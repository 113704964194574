<template>
    <div class="tr" :class="{ active: showDetails }">
        <div class="tr-data-wrap">
            <div class="tr-header-data">
                <div class="td td-type td-mobile">
                    <span>{{ getPossiblePlans[boxType].PLAN_NAME }}</span>
                </div>
                <div class="td td-id">
                    <span>#{{ Number(investment.index) + 1 }}</span>
                </div>
                <div class="td td-seeds">
                    <span class="td-value">
                        <i class="coin-icon"></i>
                        {{ investment.amount }} BNB
                    </span>
                </div>
            </div>

            <div class="tr-main-data">
                <div class="td">
                    <span class="td-mobile td-mobile-title">Opening Time</span>
                    <span>{{
                        new Date(investment.openTime * 1000).toLocaleString("en-EN", {
                            hour: "numeric",
                            minute: "numeric",

                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        })
                    }}</span>
                </div>
                <div class="td">
                    <span class="td-mobile td-mobile-title">Locking Time</span>
                    <span>{{ (investment.expiryTime - investment.openTime) / 3600 }}H</span>
                </div>
                <div class="td">
                    <span class="td-mobile td-mobile-title">Estimated seed return</span>
                    <span>{{ calcEstimatedReturn(investment)[0] }} BNB</span>
                </div>
                <div class="td">
                    <span class="td-mobile td-mobile-title">Seed Growth Rate</span>
                    <span>{{ parseFloat((investment.investReturnRate / 10000).toFixed(4)) }}%</span>
                </div>
            </div>

            <div class="tr-actions-data">
                <div class="td">
                    <button v-if="Number(investment.withdrawnAmount) === 0" :disabled="!isAvailableToClose" @click="closePosition()" class="btn btn-claimed">
                        Claim
                    </button>
                </div>
                <div class="td td-show-details">
                    <button class="a-details" @click="showDetails = !showDetails">
                        <span>Details</span>
                        <div class="icon-arrow">
                            <IconArrowDown />
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <!-- details -->
        <div class="tr-details">
            <div class="row row-link">
                <a class="a-details" href="">
                    <span>Seed return details</span>
                </a>
            </div>
            <div class="row">
                Seed growth amount:
                <div class="value">{{ calcEstimatedReturn(investment)[1] }} BNB</div>
            </div>
            <div class="row">
                Cumulative beneficial gold:
                <div class="value">??? BNB</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import Config from "../../config.json";
    var conf = Config[Config.env];
    import IconArrowDown from "./IconsComponent/IconArrowDown.vue";

    export default {
        props: ["investment", "boxType", "epoch", "plans", "maxEpoch"],
        data() {
            return {
                showDetails: false,
            };
        },
        components: {
            IconArrowDown,
        },
        computed: {
            ...mapState(["currentBlockchain", "preselectedChain", "boxLevelToData"]),
            getChain() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return chainId;
            },
            getPossiblePlans() {
                return conf[this.getChain].PLANS;
            },
            isAvailableToClose() {
                return Math.floor(new Date().getTime() / 1000) > this.investment.expiryTime;
            },
        },
        methods: {
            async closePosition() {
                try {
                    let tx = await this.$root.core.closePosition(this.boxType, this.epoch, this.investment.index);
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "warning",
                        message: `Processing your transaction...`,
                    });
                    await tx.wait(3);
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: `Your transaction has been completed.`,
                    });
                    this.$emit("updateComponent");
                } catch (error) {
                    console.log(error);
                    this.$root.core.handleError(error);
                }
            },

            calcEstimatedReturn(investment) {
                let { expiryTime, openTime, amount, investReturnRate } = investment;
                let now = Math.floor(new Date().getTime() / 1000);
                if (now > expiryTime) {
                    now = expiryTime;
                }
                const passed = now - Number(openTime);
                const ratePerSecond = Number(investReturnRate) / Number(conf[this.getChain].CONSTANTS.INVEST_RATE_PERC_ROUNDING) / (expiryTime - openTime);
                let total, onlyReward;
                if (this.maxEpoch > this.epoch) {
                    total = (Number(amount) * conf[this.getChain].CONSTANTS.MAX_SAFE_PERCENT) / 100;
                    onlyReward = 0;
                } else {
                    total = parseFloat(Number((amount * passed * ratePerSecond) / 100 + Number(amount)).toFixed(8));
                    onlyReward = parseFloat(Number((amount * passed * ratePerSecond) / 100).toFixed(8));
                }
                return [total, onlyReward];
            },
        },
    };
</script>
