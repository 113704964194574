<script setup>
    import IconArrowDown from "../IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="col col-filter">
        <div class="filter-name">Status</div>
        <div class="drop-down drop-down-filter drop-down-status" :class="{ active: dropDown }">
            <div class="drop-down-bg-click" @click="dropDown = false"></div>

            <button class="btn-drop-down" @click="dropDown = !dropDown">
                <span v-html="selectedStatus ? selectedStatus : 'Select Status'"> </span>
                <div class="icon-arrow">
                    <IconArrowDown />
                </div>
            </button>

            <div class="items-wrap">
                <div class="items-container">
                    <ul>
                        <li @click="updateValue('In Progress'), (openList = false), (selectedStatus = 'In Progress')">In Progress</li>
                        <li @click="updateValue('Claimed'), (openList = false), (selectedStatus = 'Claimed')">Claimed</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["value"],
        data() {
            return {
                selectedStatus: "In Progress",
                dropDown: false,
            };
        },
        methods: {
            updateValue(id) {
                this.$emit("input", id);
            },
        },
    };
</script>
