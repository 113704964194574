<template>
    <div class="my-boxes">
        <section class="section-filter">
            <div class="container">
                <div class="row row-filter">
                    <SelectPlan v-model="selectedBox" :plans="getPossiblePlans" />
                    <SelectNumber v-model="selectedEpoch" :maxEpoch="getMaxEpoch + 1" />
                    <SelectStatus v-model="selectedStatus" />

                    <BatchAction
                        v-if="selectedStatus !== 'Closed'"
                        :boxes="ledgers"
                        :boxType="selectedBox"
                        :epoch="selectedEpoch"
                        :actionType="'batchClosePositions'"
                        @updateComponent="updateLedgersData()"
                    />
                </div>
            </div>
        </section>
        <section class="section-table">
            <div class="container">
                <MyBoxTable
                    :boxLedgers="ledgers"
                    :boxType="selectedBox"
                    :selectedBox="selectedBox"
                    :selectedEpoch="selectedEpoch"
                    :maxEpoch="getMaxEpoch"
                    @updateComponent="updateLedgersData()"
                />
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import config from "../../../config.json";
    var conf = config[config.env];
    import BatchAction from "../../components/BatchAction.vue";
    import MyBoxTable from "../../components/BoxData/MyBoxTable.vue";
    import SelectNumber from "../../components/Filters/SelectNumber.vue";
    import SelectPlan from "../../components/Filters/SelectPlan.vue";
    import SelectStatus from "../../components/Filters/SelectStatus.vue";

    export default {
        name: "MyBoxes",
        data() {
            return {
                selectedBox: 0,
                selectedEpoch: null,
                maxEpoch: 0,
                selectedStatus: "In Progress",
                ledgers: [],
            };
        },
        computed: {
            ...mapState(["boxToEpochs", "currentAddress", "preselectedChain", "userRounds"]),
            getPossiblePlans() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return conf[chainId].PLANS;
            },
            getBoxLedger() {
                if (!this.userRounds) {
                    return [];
                }

                return this.userRounds[this.selectedBox][this.selectedEpoch];
            },
            getMaxEpoch() {
                if (!this.boxToEpochs) return 0;
                return this.boxToEpochs[this.selectedBox];
            },
        },
        methods: {
            async updateLedgersData() {
                if (this.boxToEpochs) {
                    this.selectedEpoch = this.getMaxEpoch;
                    const res = await this.$root.core.getUserRoundLedgers(this.boxToEpochs, this.currentAddress);
                    this.$store.commit("setUserRounds", res);
                } else {
                    const intervalCheckStore = setInterval(async () => {
                        if (this.boxToEpochs) {
                            this.selectedEpoch = this.getMaxEpoch;
                            clearInterval(intervalCheckStore);
                            const res = await this.$root.core.getUserRoundLedgers(this.boxToEpochs, this.currentAddress);
                            this.$store.commit("setUserRounds", res);
                            return;
                        }
                    }, 300);
                }
            },
        },
        async mounted() {
            await this.updateLedgersData();
        },
        components: { SelectPlan, SelectNumber, SelectStatus, BatchAction, MyBoxTable },
        watch: {
            selectedEpoch: async function (newVal, oldVal) {
                if (oldVal !== null && this.userRounds) {
                    const dataForSelectedEpoch = this.userRounds[this.selectedBox];

                    if (!dataForSelectedEpoch.hasOwnProperty(newVal)) {
                        const res = await this.$root.core.getUserRoundForSpecific(this.selectedBox, newVal, this.currentAddress);

                        const obj = {
                            data: res,
                            boxType: this.selectedBox,
                            epoch: newVal,
                        };
                        this.$store.commit("addToUserRound", obj);
                    }
                }
            },
            selectedBox: function (newVal) {
                if (this.selectedEpoch !== this.boxToEpochs[newVal]) {
                    this.selectedEpoch = this.boxToEpochs[newVal];
                }
            },
            getBoxLedger(newVal) {
                try {
                    if (this.selectedStatus === "Claimed") {
                        this.ledgers = newVal.filter((el) => Number(el.withdrawnAmount) !== 0).sort((a, b) => b.openTime - a.openTime);
                    } else if (this.selectedStatus === "In Progress") {
                        this.ledgers = newVal.filter((el) => Number(el.withdrawnAmount) === 0).sort((a, b) => b.openTime - a.openTime);
                    }
                } catch (error) {
                    console.log(error);
                    this.ledgers = [];
                }
            },
            selectedStatus: function (newVal) {
                try {
                    if (newVal === "Claimed") {
                        this.ledgers = this.getBoxLedger.filter((el) => Number(el.withdrawnAmount) !== 0).sort((a, b) => b.openTime - a.openTime);
                    } else if (newVal === "In Progress") {
                        this.ledgers = this.getBoxLedger.filter((el) => Number(el.withdrawnAmount) === 0).sort((a, b) => b.openTime - a.openTime);
                    }
                } catch (error) {
                    console.log(error);
                    this.ledgers = [];
                }
            },
        },
    };
</script>
