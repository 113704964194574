<script setup>
    import IconArrowDown from "../IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="col col-filter">
        <div class="filter-name">Magic box type</div>
        <div class="drop-down drop-down-filter drop-down-type" :class="{ active: dropDown }">
            <div class="drop-down-bg-click" @click="dropDown = false"></div>

            <button class="btn-drop-down" @click="dropDown = !dropDown">
                <span v-html="selectedPlan ? selectedPlan : 'Select Plan'"> </span>
                <div class="icon-arrow">
                    <IconArrowDown />
                </div>
            </button>

            <div class="items-wrap">
                <div class="items-container">
                    <ul>
                        <li v-for="plan in plans" @click="updateValue(plan.PLAN_ID), (openList = false), (selectedPlan = plan.PLAN_NAME)">
                            {{ plan.PLAN_NAME }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["value", "plans"],
        data() {
            return {
                selectedPlan: "",
                dropDown: false,
            };
        },
        mounted() {
            this.selectedPlan = this.plans[0].PLAN_NAME;
            this.updateValue(this.plans[0].PLAN_ID);
        },
        methods: {
            updateValue(id) {
                this.$emit("input", id);
            },
        },
    };
</script>
