<template>
    <div class="table table-my-box">
        <div class="table-head">
            <div class="tr">
                <div class="tr-data-wrap">
                    <div class="tr-header-data">
                        <div class="td td-id">
                            <span>ID</span>
                        </div>
                        <div class="td td-seeds">
                            <span># OF SEEDS</span>
                        </div>
                    </div>

                    <div class="tr-main-data">
                        <div class="td">
                            <span>OPENING TIME</span>
                        </div>
                        <div class="td">
                            <span>LOCKING TIME</span>
                        </div>
                        <div class="td">
                            <span>ESTIMATED SEED RETURN</span>
                        </div>
                        <div class="td">
                            <span>SEED GROWTH RATE</span>
                        </div>
                    </div>

                    <div class="tr-actions-data">
                        <div class="td">
                            <span>ACTIONS</span>
                        </div>
                        <div class="td td-show-details"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-body" v-if="boxLedgers && boxLedgers.length">
            <!-- TABLE DATA -->
            <ledger-info
                v-for="investment of boxLedgers"
                :investment="investment"
                :key="investment.index"
                :boxType="boxType"
                :epoch="selectedEpoch"
                :maxEpoch="maxEpoch"
                @updateComponent="$emit('updateComponent')"
            />
        </div>

        <div v-else class="table-body">
            <div class="tr tr-no-data">
                <div>
                    <p>No data yet</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    import LedgerInfo from "../../components/LedgerInfo.vue";
    const wait = (ms) => new Promise((res) => setTimeout(res, ms));

    export default {
        props: ["boxLedgers", "boxType", "selectedBox", "selectedEpoch", "maxEpoch"],
        data() {
            return {
                showDetails: false,
            };
        },
        components: {
            LedgerInfo,
        },
        computed: {
            ...mapState(["boxToEpochs", "currentAddress"]),
        },
        methods: {},
        async mounted() {},
        watch: {},
    };
</script>
