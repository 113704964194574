<script setup>
    import { mapState } from "vuex";
    import IconArrowDown from "../IconsComponent/IconArrowDown.vue";
</script>

<template>
    <div class="col col-filter">
        <div class="filter-name">Round Number</div>
        <div class="drop-down drop-down-filter drop-down-number" :class="{ active: dropDown }">
            <div class="drop-down-bg-click" @click="dropDown = false"></div>

            <button class="btn-drop-down" @click="dropDown = !dropDown">
                <span v-html="selectedNum ? selectedNum : 'Select Number'"> </span>
                <div class="icon-arrow">
                    <IconArrowDown />
                </div>
            </button>

            <div class="items-wrap">
                <div class="items-container">
                    <ul>
                        <li v-for="number of maxEpoch" @click="updateValue(number - 1), (selectedNum = `#${number}`)">#{{ number }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        props: ["value", "maxEpoch"],
        data() {
            return {
                selectedNum: "",
                dropDown: false,
            };
        },
        mounted() {
            this.selectedNum = `#${this.maxEpoch}`;
            this.updateValue(this.maxEpoch - 1);
        },
        methods: {
            updateValue(id) {
                this.$emit("input", id);
            },
        },
        computed: {
            ...mapState(["currentBlockchain", "preselectedChain"]),
        },
        watch: {
            maxEpoch: function (newVal, oldVal) {
                this.selectedNum = `#${newVal}`;
            },
            value: function (newVal) {
                this.selectedNum = `#${newVal + 1}`;
            },
        },
    };
</script>
